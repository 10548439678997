<template>
    <pension-module-component/>
</template>

<script>
    import PensionModuleComponent from "@/components/admin/PensionModuleComponent";

    export default {
        name: "PensionModule",
        title: "Plan de Pensión para el retiro | Private",
        components: { PensionModuleComponent }
    }
</script>

<style scoped>

</style>